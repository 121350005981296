<script setup lang="ts">
import { useWhatsappContactLink } from '~/composables/utils';

const { t } = useI18n();

const activeOrganization = useSafeActiveOrg();
const activeGame = useSafeActiveGame();

const gameBase = computed(() => {
	if (activeOrganization.value && activeGame.value) {
		return `/app/${activeOrganization.value.slug}/${activeGame.value.slug}`;
	}
	return '';
});

const organizationBase = computed(() => {
	if (activeOrganization.value && activeGame.value) {
		return `/app/${activeOrganization.value.slug}/${activeGame.value.slug}`;
	}
	if (activeOrganization.value) {
		return `/app/${activeOrganization.value.slug}`;
	}
	return '';
});

const { iAmOwner, iAmAdmin, iAmReadOnly } = usePermissionGuard();

const appLinks = computed(() => [
	{
		label: t('leaderboard.title'),
		icon: 'material-symbols-light:social-leaderboard-outline',
		to: gameBase.value,
		disabled: !gameBase.value || !iAmReadOnly.value,
	},
	// {
	// 	label: 'Dashboard',
	// 	icon: 'i-ic-round-dashboard',
	// 	to: `${gameBase.value}/dashboard`,
	// 	disabled: !gameBase.value || !iAmReadOnly.value,
	// },
	{
		label: t('team.title'),
		icon: 'i-solar-users-group-rounded-line-duotone',
		to: `${gameBase.value}/team`,
		disabled: !gameBase.value || !iAmReadOnly.value,
	},
	{
		label: t('awards.title'),
		icon: 'i-prime-trophy',
		to: `${gameBase.value}/awards`,
		disabled: !gameBase.value || !iAmAdmin.value,
	},
	{
		label: t('settings.title'),
		icon: 'i-solar-settings-line-duotone',
		to: `${gameBase.value}/team-settings`,
		disabled: !gameBase.value || !iAmOwner.value,
	},
]);

const adminLinks = computed(() => [
	// {
	// 	label: 'Billing',
	// 	icon: 'i-solar-bill-list-line-duotone',
	// 	to: `${organizationBase.value}/billing`,
	// 	disabled: !organizationBase.value || !iAmOwner.value,
	// },
	{
		label: t('organization.title'),
		icon: 'i-octicon-organization-24',
		to: `${organizationBase.value}/organization-settings`,
		disabled: !organizationBase.value || !iAmOwner.value,
	},
]);

const whatsappContactLink = useWhatsappContactLink();

const suportLinks = computed(() => {
	const links: { label: string; icon: string; to: string; disabled: boolean; target: string }[] = [
		{
			label: t('sidebar.help'),
			icon: 'mdi:lifebuoy',
			to: `${organizationBase.value}/help`,
			disabled: !organizationBase.value,
		},
	];

	if (organizationBase.value && iAmOwner.value) {
		links.push({
			label: t('sidebar.contact'),
			icon: 'mdi:headset',
			target: '_blank',
			to: whatsappContactLink.value,
			disabled: !organizationBase.value || !iAmOwner.value,
		});
	}

	return links;
});

const ui = {
	label: 'text-base py-1 font-normal',
	icon: {
		base: 'h-6 w-6',
		active: 'text-primary-600',
	},
};
</script>

<template>
	<div class="w-full lg:w-96 lg:h-full pt-12">
		<UVerticalNavigation :links="appLinks" :ui="ui" />

		<UDivider class="w-10/12 my-2" />

		<UVerticalNavigation :links="adminLinks" :ui="ui" />

		<UDivider class="w-10/12 my-2" />

		<UVerticalNavigation :links="suportLinks" :ui="ui" />
	</div>
</template>
