<script setup lang="ts">
import { useDefaultNotifications } from '~/composables/utils';

const notifs = useDefaultNotifications('navbar-notification');

const { visible } = useSidebarVisibility();
</script>

<template>
	<div class="min-h-screen flex flex-col">
		<AppNavbar />

		<div class="px-5">
			<UNotification v-if="notifs.error.isEnabled" pause-timeout-on-hover color="danger" class="mb-2" v-bind="notifs.error.bind" />
			<UNotification v-if="notifs.warning.isEnabled" pause-timeout-on-hover color="warning" class="mb-2" v-bind="notifs.warning.bind" />
			<UNotification v-if="notifs.success.isEnabled" pause-timeout-on-hover color="success" class="mb-2" v-bind="notifs.success.bind" />
		</div>

		<div class="flex-grow w-full max-w-screen-xl mx-auto p-4 lg:flex">
			<AppSidebar v-show="visible" />

			<div class="w-full p-4 rounded-xl">
				<slot />
			</div>
		</div>
	</div>
</template>
