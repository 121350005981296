<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import { useDefaultNotifications } from '~/composables/utils';

const activeOrg = useActiveOrg();
const activeOrgId = ref(activeOrg.value?.id ?? '');

watch(activeOrg, (value, _oldValue) => {
	if (value.id) {
		activeOrgId.value = value.id;
	}
});

const activeGames = useActiveGames();
const { data: games, refresh } = await useGamesByOrg(activeOrgId);

const activeGame = useSafeActiveGame();
watch(activeGame, (_value, _oldValue) => {
	refresh();
});

const i18n = useI18n();

const { isOwner } = useMyPermissions();

const formState = reactive({ name: '' });

const modalOpen = ref(false);

const items = computed(() => [
	games.value?.map((game) => ({
		value: game.id,
		label: game.name,
		to: `/app/${activeOrg.value?.slug}/${game.slug}`,
	})) || [],

	[
		{
			icon: 'i-solar-add-circle-line-duotone',
			label: i18n.t('game.create'),
			disabled: !isOwner.value,
			click() {
				formState.name = '';

				modalOpen.value = true;
			},
		},
	],
]);

const notifs = useDefaultNotifications('navbar-notification');

async function onSubmit(event: FormSubmitEvent<{ name: string }>) {
	modalOpen.value = false;

	const createdGame = await $fetch('/api/game', {
		method: 'post',
		body: {
			...event.data,

			orgId: activeOrg.value?.id,
		},
		onResponseError({ response: { status, _data } }) {
			if (_data?.statusMessage) {
				const title = i18n.t(_data.statusMessage);

				if (status >= 400 && status < 500) {
					notifs.warning.showNotification({ title });
				} else if (status >= 500 && status < 600) {
					notifs.error.showNotification({ title });
				}
			}
		},
	});

	activeGames.value.push(createdGame);

	activeGame.value = createdGame;

	refresh();

	const path = `/app/${activeOrg.value.slug}/${activeGame.value.slug}`;
	navigateTo(path);
}
</script>

<template>
	<div class="flex items-center gap-2">
		<UDropdown
			:items="items"
			:value="activeGame?.id ?? ''"
			class="border border-gray-200 dark:border-gray-800 rounded-xl py-1 px-2 text-sm lowercase"
		>
			<span class="flex items-center gap-2 justify-between">
				{{ activeGame?.name }}

				<UIcon name="i-solar-alt-arrow-down-line-duotone" />
			</span>
		</UDropdown>
	</div>

	<UModal v-model="modalOpen">
		<UCard>
			<template #header>
				<div class="flex items-center justify-between">
					<h2>{{ $t('game.create') }}</h2>

					<UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="-my-1" @click="modalOpen = false" />
				</div>
			</template>

			<UForm :state="formState" class="space-y-4" @submit="onSubmit">
				<UFormGroup :label="$t('form.name')" name="name" required>
					<UInput v-model="formState.name" :placeholder="$t('form.name')" required />
				</UFormGroup>

				<UButton color="success" type="submit">{{ $t('form.action.create') }}</UButton>
			</UForm>
		</UCard>
	</UModal>
</template>
