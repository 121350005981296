<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import type { Organization } from '~/server/database/schema';

const { data, refresh } = await useFetch('/api/me/orgs');
const activeOrg = useActiveOrg();
watch(activeOrg, (_value, _oldValue) => {
	refresh();
});

// const i18n = useI18n();
// const { isOwner } = useMyPermissions();

const formState = reactive({ name: '' });

const modalOpen = ref(false);

const items = computed(() => [
	data.value?.orgs.map((o) => ({
		label: o.org.name,
		to: `/app/${o.org.slug}`,
	})) || [],

	// [
	// 	{
	// 		icon: 'i-solar-add-circle-line-duotone',
	// 		label: i18n.t('organization.create'),
	// 		disabled: !isOwner.value,
	// 		click: () => {
	// 			formState.name = '';
	//
	// 			modalOpen.value = true;
	// 		},
	// 	},
	// ],
]);

async function onSubmit(event: FormSubmitEvent<{ name: string }>) {
	modalOpen.value = false;

	const organization = await $fetch<Organization>('/api/organization', {
		method: 'POST',
		body: event.data,
	});

	refresh();

	navigateTo(`/app/${organization.slug}`);
}
</script>

<template>
	<div class="flex items-center gap-2">
		<UDropdown
			:items="items"
			:value="activeOrg?.id ?? ''"
			class="border border-gray-200 dark:border-gray-800 rounded-xl py-1 px-2 text-sm h-8 lowercase"
		>
			<span class="flex items-center gap-2 justify-between">
				{{ activeOrg?.name }}

				<UIcon name="i-solar-alt-arrow-down-line-duotone" />
			</span>
		</UDropdown>
	</div>

	<UModal v-model="modalOpen">
		<UCard>
			<template #header>
				<div class="flex items-center justify-between">
					<h2>{{ $t('organization.create') }}</h2>

					<UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="-my-1" @click="modalOpen = false" />
				</div>
			</template>

			<UForm :state="formState" class="space-y-4" @submit="onSubmit">
				<UFormGroup :label="$t('form.name')" name="name" required>
					<UInput v-model="formState.name" :placeholder="$t('form.name')" required />
				</UFormGroup>

				<UButton color="success" type="submit">{{ $t('form.action.create') }}</UButton>
			</UForm>
		</UCard>
	</UModal>
</template>
